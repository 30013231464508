import React from "react"
import Base from "../../components/templates/Base"
import ForgotPasswordForm from "../../components/Login/ForgotPasswordForm"

const ForgotPassword = ({ location }) => {
  return (
    <Base>
      <ForgotPasswordForm location={location} />
    </Base>
  );
};

export default ForgotPassword;
import React, { useState } from "react"
import axios from 'axios';

import Emoji from '../helpers/Emoji'

import * as SignUpStyles from "../SignUp/signup.module.scss"
import { Link } from "gatsby";

import { useForm } from "react-hook-form";
import { navigate } from "gatsby"
import Spinner from "../elements/Spinner";
import Password from "../elements/PasswordField";

const ForgotPassword = (props) => {

  const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);

  const { register, handleSubmit, formState } = useForm({
    mode: 'onBlur',
    defaultValues: {
      grant_type: "password",
      client_id: "mercury",
    },
  });
  const { errors } = formState;

  const onSubmit = data => {
    setIsSubmitting(true);


    // axios.post(`${process.env.GATSBY_APIURL}/a/Account/ResetPassword`, new URLSearchParams(data).toString())
    axios.post(`${process.env.GATSBY_APIURL}/a/Account/ForgotPassword`, new URLSearchParams(data).toString())
    .then((response) => {
      setIsSubmitting(false);
      setIsSuccess(true);
    })
    .catch(function (error) {
      setIsSubmitting(false);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        //console.log('Error', error.message);
      }
      //console.log(error.config);
    });
  };
  const onError = (errors, e) => {
    setIsSubmitting(false);
    //console.log(errors, e);
  }
  return (
    <section className={SignUpStyles.signUp}>
      <div className={SignUpStyles.innerDiv}>
        <div className="component">
          <h1>Forgot Password</h1>
          <div className={SignUpStyles.subText}>To reset your password for Loaded, enter your email address and we'll send you an email with instructions.  <Emoji symbol="💪" label="Muscles" /> </div>


          {!isSuccess ? 
					<form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className={SignUpStyles.emailForm}>
              <div className={SignUpStyles.formDiv}>
                <div className={SignUpStyles.inputWrap}>
                  <input type="text" placeholder="Email Address" className="text-input" {...register("username", { required: true, pattern: "[^@\s]+@[^@\s]+\.[^@\s]+" } )} />
                  {(errors !== undefined && errors.email) && <span className={SignUpStyles.hasErrors}>A valid email address is required</span>}
                </div>

                <div className={`${SignUpStyles.inputWrap}`}>
                  <button type="submit" className={SignUpStyles.button}><span className={SignUpStyles.buttonInner}>{isSubmitting && <Spinner />} Reset Password &rarr;</span></button>
                </div>

              </div>
            </div>
          </form>
					:
					<div className={SignUpStyles.postMessage}>Please check your email for a reset password link</div>
					}

          <div className={SignUpStyles.disclaimer}>
            By creating a Loaded account you agree to the <Link to="/end-user-licence-agreement">End User Licence Agreement</Link> and <Link to="/privacy-policy">Privacy Policy.</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ForgotPassword;
